import React from 'react';
import { Helmet } from 'react-helmet'
const IndexPage = () => {
  return (<>
    <Helmet>
      <title>boatval.com - online presence for the marine industry</title>
      <description>building online experiences for the boat industry world-wide</description>
    </Helmet>
    <div class="context">
      <h1>BoatVal</h1>
      <h3>building online presence solutions</h3>
      <h3>for the marine industry</h3>
    </div>


    <div class="area" >
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div >
  </>)
}

export default IndexPage;
